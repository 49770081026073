/* Color Pallette */
/* 
Dark Blue = #231955 
Light Blue = #1f4690
Dark Yellow = #e8aa42
Light Yellow = #ffe5b4
Light Grey = #d1d1d1
White Text = whitesmoke
Dark Grey = #4C4E52
*/

/* Universal Styles */
html,
body {
  height: 100%;
  margin: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h3,
h4 {
  margin: 2rem;
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 768px) {
  h3 {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 768px) {
  p {
    font-size: 12px !important;
  }
}
.link {
  color: #4c4e52;
  font-weight: bold;
  margin: 10px 25px;
  text-decoration: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Navbar  */
.navbar {
  background-color: #231955;
  line-height: 1rem;
  min-width: 255px;
}
.navbar-brand {
  max-width: 80%;
}
.nav-link:hover {
  font-size: 1.1rem;
  transition: font-size 0.1s ease;
}
@media screen and (max-width: 768px) {
  .nav-link {
    padding-top: 1rem !important;
  }
}
.logo {
  height: 20%;
  width: 20%;
  padding: 0.7rem;
}
.slogan {
  color: whitesmoke;
  display: inline-flex;
  padding-left: 2rem;
}
@media screen and (max-width: 600px) {
  .slogan {
    font-size: 8px;
    padding-left: 1rem;
  }
}

/* Main Container  */

.main-container {
  background-color: #d1d1d1;
  padding: 3rem;
  margin-top: 2rem;
}

/* Footer  */
.footer {
  background-color: #ffe5b4;
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 1.5rem;
}
.footer-link:hover {
  color: whitesmoke;
  font-size: 1.1rem;
  line-height: 1rem;
  transition: font-size 0.3s;
}
.copyright {
  font-size: 0.8em;
  padding: 20px;
}

/* Home Page  */
.description {
  color: #4c4e52;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 3rem;
}
@media screen and (max-width: 768px) {
  .description {
    width: 100%;
    margin: 0 auto;
    padding: 2rem;
  }
}
.carousel-item {
  margin: auto;
}
.carousel-inner img {
  margin: auto;
  width: 70% !important;
  height: auto;
}
@media screen and (max-width: 768px) {
  .carousel-inner img {
    width: 100% !important;
  }
}
.reviews {
  background-color: #fefbf6;
}
.reviews-carousel {
  min-height: 25vh;
}
@media screen and (max-width: 768px) {
  .reviews-carousel {
    min-height: 40vh;
  }
}
@media screen and (max-width: 450px) {
  .reviews-carousel {
    min-height: 50vh;
  }
}
.review-name {
  color: #4c4e52;
  display: flex;
  width: 70%;
  margin: 0 auto;
  padding-left: 2rem;
}
.review-text {
  color: #4c4e52;
  display: flex;
  width: 70%;
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .review-name,
  .review-text {
    width: 90%;
  }
}
.bullets {
  text-align: center;
  width: 60%;
  margin: 0 auto;
  color: #4c4e52;
  font-size: 1.2rem;
}
.bullet-item {
  padding: 10px;
  text-align: left;
}
@media screen and (max-width: 600px) {
  .bullet-item {
    font-size: 16px;
  }
}
.leafletContainer {
  width: 100%;
  height: 200px;
  overflow-x: hidden;
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .leafletContainer {
    height: 400px;
  }
}
.location-container {
  padding-top: 2rem;
  color: #4c4e52;
}
.address-info {
  text-align: center;
}

/* About Page  */
.about-img {
  width: 100%;
  height: auto;
  margin: auto;
  padding: 2rem;
}

/* Services Page  */
.services-img {
  width: 100%;
  height: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.card {
  background-color: #fefbf6 !important;
}
.img-div {
  display: flex;
  justify-content: center;
}

/* Reviews Page */
.feedback-form {
  width: 65%;
  margin: 0 auto;
  min-height: calc(100vh - 30rem);
}
.feedback-form label {
  line-height: 2.7rem;
  font-weight: bold;
}
.feedback-form textarea {
  min-height: 100px;
  font-size: 18px;
}

/* Contact Page */
.contact-me {
  width: 100%;
}
#contact-form {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  border-color: #1f4690;
  border: 1px solid;
  padding: 15px;
  border-radius: 5px;
  background-color: #fefbf6;
  margin-bottom: 50px;
  text-align: left;
}
#contact-form label {
  line-height: 2.7rem;
}
#contact-form textarea {
  min-height: 100px;
  font-size: 14px;
}
#submit-btn {
  background-color: #e8aa42;
  color: white;
}

/* Not Found Component */
.not-found {
  height: 75vh;
  vertical-align: middle;
  text-align: center;
}
